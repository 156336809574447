.loader {
    animation: spin 1s linear infinite;
    transform-origin: center center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
