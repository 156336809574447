.header {
    display: flex;
    padding: 16px 32px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--Light-Gray-Light-Gray-3, #E5E9EB);
}
.right span{display: flex; cursor: pointer;color: #000;

    /* Headings/UI/UI Text 14 Semi Bold */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: -0.084px;}
    /* .btn-sign-in{
        background: var(--Primary-PR400, #55C6EF);
        color: var(--Dark-Gray-Dark-Gray-1, #1A2024);
    } */
    .notification-button{position: relative;}
   
    
      .settings-box ul li img {
        margin-right: 16px;
      }
      [dir='rtl'] .settings-box ul li img {
        margin-right: 0px;
    }
      .settings-box ul li:hover {
        border-radius: 6px;
        background: var(--Light-Gray-Light-Gray-5, #f6f8f9);
      }
      .settings-box ul li:nth-child(4) {
        margin-bottom: 20px;
      }
      .settings-box ul li:nth-child(4)::after {
        content: "";
        width: 210px;
        height: 1px;
        background: var(--Light-Gray-Light-Gray-4, #eef0f2);
        position: absolute;
        bottom: -10px;
      }
      .settings-box ul li:nth-child(5) {
        margin-top: 20px;
      }
       
      .settings-box ul li:nth-child(8) {
        margin-bottom: 20px;
      }
      .settings-box ul li:nth-child(8)::before {
        content: "";
        width: 210px;
        height: 1px;
        background: var(--Light-Gray-Light-Gray-4, #eef0f2);
        position: absolute;
        top: -5px;
      }
      .settings-box ul li:last-child {
        margin-top: 10px;
        margin-bottom: 0px;
      }
