.startAdornment {
  position: absolute;
  left: 10px;
  top: 13px;
}
.dateselect .endAdornment{top: 13px;}
.endAdornment {
    position: absolute;
    right: 16px;
    top: 22px;
}
.endAdornmentInput {
    padding-right: 15px !important;
}
.startAdornmentInput {
    padding-left: 60px !important;
}

.arabic .endAdornment {
    left : 10px;
    right:  unset;
}
.arabic .startAdornment {
    left: unset;
    right: 10px;
}