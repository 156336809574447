@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./assets/Fonts/roboto-t/stylesheet.css");
@import url("./assets/Fonts/GE-SS/stylesheet.css");
@import url("./assets/Fonts/FE/stylesheet.css");
@import url("./assets/Fonts/NotoKufiArabic/stylesheet.css");

/* @import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100..900&display=swap'); */

body[dir="ltr"] {
  font-family: "roboto";
}
body[dir="rtl"] {
  font-family: "Noto Kufi Arabic";
  /* font-family: "Noto Kufi Arabic", sans-serif; */
}
body[dir="rtl"] select {
  font-family: "roboto";
}
.custominput::placeholder {
  font-family: "FE-Font";
  color: #a0a0a0;
  font-size: 45.714px;
  color: rgba(0, 19, 127, 0.3);
}
.plattypestyle select {
  background-position: 97% 48%;
}
[dir="rtl"] .forceltr_select select {
  direction: ltr;
  text-align: right;
}
.profile-seting-multitel label {
  color: #48535b;
  font-size: 1rem;
  margin-bottom: 0;
}
.profile-seting-multitel .multi-tel {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #b0babf;
  background: #e5e9eb;
  height: 50px;
  margin-bottom: 5px;
}
.profile-seting-multitel .country-selector,
.profile-seting-multitel .mobilenumber {
  background: #e5e9eb;
}
.yourProfile .disablebtn {
  margin-top: 50px;
  display: flex;
  padding: 8px 48px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  opacity: 0.5;
  background: var(--Primary-PR400, #55c6ef);
  color: var(--Dark-Gray-Dark-Gray-1, #1a2024);
  text-align: center; /* Headings/UI/UI Text 14 Semi Bold */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.084px;
}
/********** sign in page common css start *******************/
.right_cont {
  justify-content: center;
  align-items: center;
  padding-top: 5% !important;
}
.right_cont form label {
  color: #48535b;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  letter-spacing: -0.16px;
  margin-top: 0px;
  margin-bottom: 5px;
}
.form input {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Mid-Gray-Mid-Gray-5, #b0babf);
  background: var(--black-white-white, #fff);
}
.primary-button {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--Primary-PR400, #55c6ef);
  width: 100%;
  color: var(--Dark-Gray-Dark-Gray-1, #1a2024);
  text-align: center;
  opacity: 8; /* Headings/Heading 18 Semi Bold */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
  margin-bottom: 24px;
}
.right_cont .content a {
  color: #00384d;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.16px;
  text-decoration-line: underline;
  margin-left: 5px;
}
.right_cont .content p {
  color: #1a2024;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
}
.sign_in_page .input-columns {
  width: 100% !important;
}
/* .sidebar li:hover span{color: #00384D;font-weight: 600;} */
/* .sidebar li:hover span img{filter: invert(0%) sepia(0%) brightness(0) contrast(100%); } */

.offersSection .slick-dots {
  bottom: 17px !important;
}
.hero-slider .slick-dots ul {
  width: 60px;
  max-width: 100px;
  height: 14px;
  margin: 0 auto;
  border-radius: 14px;
  background-color: #ffffff4d;
}

.signup-right-content .label {
  color: var(--Dark-Gray-Dark-Gray-5, #48535b);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 162.5% */
  letter-spacing: -0.16px;
  margin-top: 24px;
  margin-bottom: 5px;
}
/********** sign in page css end *******************/
select {
  -webkit-appearance: none; /* For older Safari */
  -moz-appearance: none; /* For older Firefox */
  background-image: url("../src/assets/images/Chevronsmalldown.svg"); /* Custom arrow icon */
  background-repeat: no-repeat;
  background-position: 90% 48%;
  height: 50px;
}
[dir="rtl"] select {
  background-position: 6% 48%;
}
.banklist select {
  background-position: 96% 50%;
}
[dir="rtl"] .banklist select {
  background-position: 3% 50%;
}
.infoCard ul li span:before {
  content: "•";
  position: absolute;
  left: -16px;
  top: 10px;
  transform: translateY(-50%);
  font-size: 20px;
  color: #48535b;
  right: -16px;
}
.infoCard ul li span:before {
  right: -16px;
}
.infoCard ul li {
  position: relative;
}
.ovrveiw-tab .downloadbtn,
.ovrveiw-tab .downloadbtn button {
  width: 100%;
}
.policy-over .downloadbtn button {
  width: 100%;
}

.download-wrapper > .downloadbtn:nth-child(4),
.download-wrapper > .downloadbtn:nth-child(5) {
  max-width: 32.6%;
}
.vehical-download .downloadbtn {
  width: 75%;
}
.pass_valid_cont h4,
.pass_valid_cont p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.manage-policy-box span:last-child {
  color: #1a2024;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-top: -8px;
  display: flex;
}
/* .manage-policy-box{width: 49%;} */
.backdropModal h1 {
  text-align: left;
}
[dir="rtl"] .backdropModal h1 {
  text-align: right;
}
.border-b-last td:last-child div:last-child {
  border-bottom: 0;
}
.backdropModal .titleDescription p {
  text-align: left;
}
.sent-text {
  color: #48535b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  letter-spacing: -0.16px;
}
.backdropModal .recive-text {
  color: #00384d;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.16px;
  text-decoration-line: underline;
}
.backdropModal .verify-content {
  box-shadow: none;
}
.backdropModal .new-policy-modal {
  border-radius: 26px;
}
.yesNoButons button:first-child,
.yesno-tab-box button:first-child {
  border-radius: 10px 0px 0px 10px;
}
.yesNoButons button:last-child,
.yesno-tab-box button:last-child {
  border-radius: 0px 10px 10px 0px;
}
[dir="rtl"] .yesNoButons button:first-child,
[dir="rtl"] .yesno-tab-box button:first-child {
  border-radius: 0px 10px 10px 0px;
}
[dir="rtl"] .yesNoButons button:last-child,
[dir="rtl"] .yesno-tab-box button:last-child {
  border-radius: 10px 0px 0px 10px;
}
.yournewcardetails .formField {
  flex: 1 1 calc(50% - 10px);
}
.yournewcardetails .formField label {
  color: #48535b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  letter-spacing: -0.16px;
}
.adjustmodaltabs div button:first-child {
  padding-left: 0;
}
.increse-box-tab .yesno-tab-box {
  margin-bottom: 0 !important;
  padding-top: 0;
  gap: 10px;
}
/* .enhancetabs .yesno-tab-box:first-child button{border-radius: 10px;} */
.increse-box-tab {
  margin-bottom: 20px;
}
/* .carInfoPage .yesno-tab-box button:first-child{border-right: 0;} */
.AdjustCarValuePopup .new-policy-modal {
  width: 45%;
}
[dir="rtl"] .AdjustCarValuePopup .titleDescription h1 {
  text-align: right;
}
[dir="rtl"] .AdjustCarValuePopup .titleDescription p {
  text-align: right;
}
.AdjustCarValuePopup .new-policy-modal .close-modal-icon {
  justify-content: flex-end;
}
.AdjustCarValuePopup .new-policy-modal .titleDescription {
  margin-top: -42px;
}
.AdjustCarValuePopup .new-policy-modal .adjustmodaltabs div:first-child {
  margin-bottom: 0;
}
.increse-box-tab .yesno-tab-box button {
  display: flex;
  width: 32.3%;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  border-radius: 10px;
  height: auto;
}
.adjustsumvalue .yesno-tab-box button {
  height: auto !important;
}
.increse-box-tab .yesno-tab-box button .top_title:first-child {
  color: #48535b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}
.increse-box-tab .yesno-tab-box button .top_title:nth-child(2) {
  color: #1a2024;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.18px;
}
.adjust-buttons button {
  width: 48%;
  height: 40px;
}
.adjust-buttons .primary-button {
  font-size: 14px !important;
}
/*** enhance your coverage page ******/
.enhancetabs button {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.enhancetabs div {
  width: 100%;
}
.enhancetabs .yesno-tab-box {
  margin-bottom: 0px !important;
}
.enhancetabs div:first-child {
  margin-bottom: 0;
}
.apply-input .form-input input {
  width: 100%;
  display: flex;
  padding: 8px 16px;
  height: 42px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid #b0babf;
  background-color: #fff;
}
.apply-input .form-input {
  width: 85%;
}
.apply-input button {
  width: 68px;
  display: flex;
  padding: 4px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background-color: #55c6ef;
  height: 50px;
  margin-top: 30px;
  color: #1a2024;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.084px;
}
.apply-input button span {
  font-size: 14px;
}
.apply-input .form-input {
  min-height: 75px;
}
.sidebar .li-box:last-child {
  border-bottom: 0;
}
/*** enhance your coverage page ******/
/************** hero slider **********************/
.slick-dots li {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.3);
  width: 6px !important;
  height: 6px !important;
  margin: 4px !important;
}
.slick-dots {
  bottom: 9px;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 6px !important;
  height: 6px !important;
  content: "•";
  text-align: center;
  opacity: 8;
  color: rgba(255, 255, 255, 0.7);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 30px;
  height: 30px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button {
  font-size: 14px !important;
  line-height: 1 !important;
  width: 6px !important;
  height: 6px !important;
}
.slick-dots li {
  background-color: transparent;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75 !important;
  color: #00719a !important;
}
.slick-dots li button:before {
  color: #ffffff !important;
  opacity: 0.7 !important;
  top: -7px !important;
}
.custom-arrow.prev-arrow {
  display: none;
}
.custom-arrow.next-arrow {
  display: none;
}
.slider-image-div img {
  min-width: 100%;
  object-fit: cover;
}
/************** hero slider **********************/
/**************** verify otp page *****************/
.otp_input {
  display: flex;
  width: 80px !important;
  height: 80px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--Mid-Gray-Mid-Gray-5, #b0babf);
  background: var(--black-white-white, #fff);
}
.otp_input::placeholder {
  color: #d5dadd;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 81.25% */
  letter-spacing: -0.32px;
}
input::placeholder {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.18px;
}
.otpinput div {
  gap: 24px;
}
.yourProfile .inputGrid .form-input {
  width: 50%;
}
.verify-content .otp_input input[type="number"]::-webkit-outer-spin-button,
.verify-content .otp_input input[type="number"]::-webkit-inner-spin-button,
.verify-content .otp_input input[type="number"] {
  -webkit-appearance: none !important;
  -moz-appearance: textfield;
  appearance: textfield; /* Add these rules to remove arrows in Firefox */
} /* Remove arrows from number input */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; /* WebKit browsers */
  margin: 0; /* Optional - if you want to remove the margin */
}
/**************** verify otp page *****************/
/********************* Review and Confirm *******************/
.summary-box .summary-conntent {
  margin-top: 0 16px 16px 16px;
  padding: 16px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid var(--Light-Gray-Light-Gray-2, #dde2e4);
  background-color: #fff;
}
/********************* Review and Confirm *******************/
/************* plan selctor modal css *******************/
.planselectormodalmain .close-modal-icon,
.choose-your-plan .close-modal-icon {
  justify-content: end;
  margin-top: 10px;
}
.planselectormodalmain .new-policy-modal {
  width: 1300px;
  max-width: 100%;
}
.summarypopup .summarymodal .close-modal-icon {
  justify-content: flex-end;
}
.summarypopup .summarymodal h1 {
  margin-top: -38px;
}
/************* plan selctor modal css *******************/
.dateselect input {
  display: inline;
}
[dir="rtl"] .dateselect input {
  text-align: right;
}
.summarypopup .bg-white:first-child {
  max-width: 624px;
  height: auto;
}
.summary-content-div {
  overflow-y: scroll;
}
.choose-your-plan .new-policy-modal {
  width: 624px;
}
.uplodad-car-div h1,
.uplodad-car-div p {
  text-align: left;
}
.confirm-btn {
  display: inline;
}
.policycardbox .policy_card_root .policy_card_content {
  padding: 16px;
}
.policycardbox .policy_card_content h3 {
  margin-top: 10px;
}
.policycardbox .policy_card_content .card-title {
  margin-bottom: 10px;
}
.policycardbox .policy_card_content .date-info {
  margin-bottom: 10px;
}
.policycardbox .policy_card_root button img {
  width: 24px;
  height: 24px;
}
.selectyourcard .plancard {
  min-height: 350px;
}
.selectyourcard .plancard button {
  position: absolute;
  bottom: 20px;
  width: 80%;
}
.selectyourcard .plancard button:disabled {
  background-color: #fff;
  opacity: 0.5;
}
input:disabled {
  background-color: #e5e9eb;
}
.profile-seting-multitel .formField .multi-tel {
  justify-content: start;
}
.multi-tel {
  height: 50px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(../src/assets/images/date_range.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}
/* .carfinancepage .yesno-tab-box {margin-bottom: 5px;} */
.dateselect input,
.Howinput input {
  color: #000;
  font-weight: normal;
}
.reviwetable table > tbody > tr:last-child td:first-child {
  border-bottom: 0;
}
.reviwetable table > tbody > tr:last-child td div:last-child {
  border-bottom: 0;
}
.summarypopup .summarymodal .new-policy-modal {
  width: 624px;
  max-width: 100%;
}
.amountInput .startAdornment {
  top: 13px;
  left: 8px;
}
.amountInput .form-input {
  min-height: auto;
}
.confirmtransfer .form-input {
  min-height: auto;
  width: 100%;
}
.transfersigninmodal .new-policy-modal {
  background: var(--Light-Gray-Light-Gray-5, #f6f8f9);
}
/************* file claim css start *********************/
.uploaddocs input:first-child {
  background-color: #f6f8f9;
}
.fileclaim-box .policy-cards {
  width: 616px;
  max-width: 100%;
}
.Settled {
  border-radius: 4px;
  background-color: #eef0f2;
  display: flex;
  padding: 0px 4px;
  align-items: center;
  color: #252c32;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.Processed {
  display: flex;
  padding: 0px 4px;
  align-items: center;
  border-radius: 4px;
  background-color: #fffcc2;
  color: #835101;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.faq-answer ul li {
  list-style-type: disc;
}
.faq-answer ul {
  padding-left: 20px;
}
.Policy-Transfer-Request .new-policy-modal {
  width: 500px;
  max-width: 100%;
}
.Policy-Transfer-Request .new-policy-modal .close-modal-icon {
  justify-content: end;
}
.cpr-exists-modal .new-policy-modal {
  display: flex;
  width: 500px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  max-width: 100%;
}
.transferpolicyinput .form-input label {
  color: #48535b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.16px;
}

/*********** changes method input css *********/
.sidebar .active a span {
  color: #00384d;
  font-weight: 600;
}
.sidebar .active a span img {
  filter: brightness(0) saturate(100%) invert(17%) sepia(78%) saturate(905%)
    hue-rotate(159deg) brightness(90%) contrast(104%);
}
.sidebar li.active {
  border-radius: 6px;
  background: #e5e9eb;
}
.changemehodinputs .formField {
  min-height: auto;
}
.changemehodinputs .formField input {
  border-radius: 0;
  border: 1px solid #dde2e4;
  font-weight: normal;
  font-size: 16px;
}
.changemehodinputs .formField:first-child input {
  border: 0;
  border-bottom: 1px solid #dde2e4;
}
.changemehodinputs .flex .formField:first-child input {
  border-bottom: 1px solid #dde2e4;
}
.changemehodinputs .flex .formField:nth-child(2) input {
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid #dde2e4;
  border-left: 1px solid #dde2e4;
}
.changemehodinputs .formField:last-child input {
  border: 0;
  border-bottom: 1px solid #dde2e4;
}
.changemehodinputs .formField input::placeholder {
  color: #dde2e4;
  font-weight: normal;
  font-size: 16px;
}
.signinright_cont .formField .endAdornment{top:12px!important}
.Toastify__close-button > svg {
  height: 16px;
  width: 14px;
  fill: white !important;
}
.hideclose{visibility: hidden;}
.Toastify__toast--success
  .Toastify__close-button.Toastify__close-button--light {
  height: 16px;
  width: 14px;
  color: #b0babf !important;
  margin-top: 9px;
  opacity: 8;
}
.Toastify__toast--success .Toastify__close-button--light {
  opacity: 8 !important;
}
.showme span {
  font-size: 14px;
}
.changegaragebtn span {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
}
.vheical-table table tr:last-child td {
  border-bottom: 0;
}
.managemypolicycard .bhdvalue {
  margin-top: 5px;
}
.managepolicy .manage-policy-box {
  border-radius: 10px;
  border: 2px solid transparent;
  background-color: #f6f8f9;
}
.downloadbtn .button div {
  width: 90%;
  text-align: left;
  justify-content: left;
}
[dir="rtl"] .downloadbtn .button div .content-start{text-align: right; margin-right: 5px;}
.downloadbtn .button div .content-start {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  margin-left: 5px;
}
.Differencesplates .close-modal-icon {
  justify-content: space-between;
  width: 100%;
}
/*********** changes method input css *********/
/******** toast *******************/
.Toastify__toast {
  border-radius: 6px !important;
  background: var(--Dark-Gray-Dark-Gray-3, #303940) !important;
  box-shadow: 0px 0px 1px 0px rgba(26, 32, 36, 0.32),
    0px 8px 16px 0px rgba(91, 104, 113, 0.24) !important;
  color: var(--Light-Gray-Light-Gray-1, #d5dadd) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex !important;
  width: 352px;
  padding: 16px !important;
  justify-content: space-between;
  align-items: center;
  right: 31px;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1 1;
}
/*************** PolicyTypePage ****************/
.PolicyTypePagecard .optioncard {
  padding: 12px 16px;
}
.PolicyTypePagecard .optioncard .items-center {
  display: flex;
}
.PolicyTypePagecard .optioncard .items-center input {
  margin-bottom: 0;
}
.PolicyTypePagecard .optioncard .items-center h2 {
  color: #1a2024;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
}
.workshop select {
  background-position: 95% 51%;
}
[dir="rtl"] .workshop select {
  background-position: 4% 55%;
  text-align: right;
}
[dir="rtl"] .dateselect .endAdornment {
  left: 14px;
  top: 14px;
}
.workshop select option::placeholder {
  opacity: 0.3;
}
/* .workshop , .dateselect .form-input{min-height: auto;} */
.cpr-exists-modal .close-modal-icon {
  justify-content: space-between;
} /*** cpr exit modal title custom css used ********/
.newprimium label {
  color: #1a2024;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.16px;
}
.newprimium input {
  border-radius: 10px;
  border: 1px solid #b0babf;
  background-color: #fff;
  width: 100%;
  height: 77px;
}
.newprimium small {
  color: #48535b;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.12px;
}
.IBAN label {
  color: #48535b;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.16px;
}
.addnewbenificiary .new-policy-modal {
  margin-top: 30%;
}
.editpopover .react-tiny-popover-container {
  transform: translate(1224px, 407px) !important;
  z-index: 9999;
}
.react-tiny-popover-container {
  z-index: 999;
}
.TrusteesDetails .new-policy-modal {
  width: 500px;
  max-width: 100%;
  margin-top: 32%;
}
.EditSharePercentage .new-policy-modal {
  width: 500px;
  max-width: 100%;
  margin-top: 8%;
}
.tableinput .form-input {
  min-height: auto;
}
.tableinput .form-input input {
  height: 32px;
  width: 100px;
  font-size: 14px;
  color: #252c32;
  border: 1px solid #dde2e4;
}
.tableinput .form-input .relative {
  width: 100px;
}
.tableinput .form-input .relative .endAdornment {
  right: 1px;
  top: 1px;
}
[dir="rtl"] .tableinput .form-input .relative .endAdornment {
  left: 1px;
  right: unset;
}
.tableinput .form-input .relative .endAdornment .bg-gray-100 {
  width: 23px;
  text-align: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 30px;
  padding-top: 3px;
}
[dir="rtl"] .tableinput .form-input .relative .endAdornment .bg-gray-100 {
  width: 23px;
  text-align: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 30px;
  padding-top: 3px;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.fundmanagementbox .fund-managment .managemypolicycard {
  flex-direction: row;
  display: flex;
  align-items: start;
}
.fundmanagementbox .fund-managment .managemypolicycard .ftext {
  margin-top: 0;
  font-weight: 500;
}
.fundmanagementbox .fund-managment {
  height: 92px;
  border: 1px solid var(--Light-Gray-Light-Gray-2, #dde2e4);
}
.paymenthistory .paymenthistroybox:nth-child(-n + 3) {
  min-width: 25%;
}
.managemypolicy .managemypolicycard,
.managepolicy .managemypolicycard {
  flex-direction: row;
  display: flex;
}
.policyoverview .overviewcard .ftext {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #48535b;
  margin-top: 3px;
}
.managemypolicy .manage-policy-box {
  /* outline: 2px solid transparent; */
  border-radius: 10px;
  /* border: 1px solid var(--Light-Gray-Light-Gray-2, #dde2e4); */
  background: var(--Life-Colors-Skin-200, #fbf7f0);
}
.managemypolicy .manage-policy-box:hover {
  background: var(--Life-Colors-Skin-200, #fbf7f0);
  border: 2px solid var(--Life-Colors-Primary-500, #168617);
}
.nationlaityselect select {
  background-position: 95% 45%;
}
.circlechart canvas {
  height: 400px !important;
  width: 400px !important;
}
[dir="rtl"] .Toastify__toast {
  direction: rtl;
}
[dir="rtl"] .Toastify__toast-container--top-right {
  left: 100px;
  right: unset;
}
.fund-redirect-tbl .formField {
  height: auto;
  min-height: auto;
  position: relative;
}
.table-allocation tr > td:last-child {
  width: auto !important;
}
.fund-redirect-tbl tr > td:last-child,
.table-allocation tr > td:nth-child(4) {
  width: 10%;
}
.fund-redirect-tbl tr > td input,
.table-allocation tr > td:nth-child(4) input {
  width: 102px;
  height: 32px;
}
.fund-redirect-tbl tr > td:last-child .endAdornment {
  right: 5px;
  top: 1px;
}
.table-allocation tr > td:nth-child(4) .endAdornment {
  right: 4px;
  top: 1px;
}
.fund-redirect-tbl tr > td:last-child .endAdornment .bg-gray-100,
.table-allocation tr > td:nth-child(4) .endAdornment .bg-gray-100 {
  width: 21px;
  text-align: center;
  height: 30px;
  line-height: 2;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.fundnamemodal .new-policy-modal {
  margin-top: 50px;
  background: #f6f8f9;
  width: 1200px;
  max-width: 100%;
  max-height: 100vh;
  overflow-y: auto;
}
/******** track a claim ***********/

.PROGRESS,
.Repairing {
  display: flex;
  width: max-content;
  background-color: #d29404;
  padding: 0px 8px;
}
.REJECTED,
.Rejected {
  display: flex;
  background-color: #cc0905;
  width: max-content;
  padding: 0px 8px;
}
.Paid {
  border-radius: 10px;
  background-color: #119c2b;
  display: flex;
  width: 50px;
  height: 20px;
  padding: 0px 8px;
  align-items: center;
}
.COMPLETED,
.Completed {
  display: flex;
  width: 90px;
  background-color: #00719a;
  text-align: center;
}
.track-claim-stepper {
  margin-left: 13px;
  margin-bottom: 36px;
  align-items: flex-end;
  justify-content: left;
}
.downloaddocumentsmodal .new-policy-modal {
  width: 560px;
  max-width: 100%;
}
.downloaddocumentsmodal .new-policy-modal .close-modal-icon {
  justify-content: space-between;
}
.downloaddocumentsmodal .new-policy-modal .downloadbtn .button {
  width: 100%;
}
.fontlg span {
  font-size: 18px;
}
.changeplatepopup .close-modal-icon {
  justify-content: space-between;
}
.profile-seting-multitel button .content-start {
  color: #00384d;
}
.downloadbtn button {
  width: 100%;
}
.loader {
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.filterfund .isActive {
  background-color: #e3f8ff;
  color: white;
  border: 2px solid #00719a;
}
.claimdetailsbox .button-blue .button span,
.AddFund span {
  font-size: 14px;
}
.managemypolicy .managemypolicycard .cardvalue {
  font-size: 18px;
  font-weight: 500;
}
.managemypolicy .managemypolicycard .ftext {
  margin-top: 10px;
}
.sidebar .li-box:first-child li:first-child {
  display: none;
}
.numberPlatesTypesPopup .grid .plate-box:nth-child(odd) {
  border-bottom: 1px solid #dde2e4;
}
.numberPlatesTypesPopup .grid .plate-box:nth-child(even) {
  border-bottom: 1px solid #dde2e4;
  border-left: 1px solid #dde2e4;
}
.numberPlatesTypesPopup .grid .plate-box:first-child {
  border-top-left-radius: 16px;
}
.numberPlatesTypesPopup .grid .plate-box:nth-child(2) {
  border-top-right-radius: 16px;
}
.numberPlatesTypesPopup .grid .plate-box:last-child {
  border-bottom-right-radius: 16px;
  border-bottom: 0;
}
.numberPlatesTypesPopup .grid .plate-box:nth-last-child(2) {
  border-bottom-left-radius: 16px;
  border-bottom: 0;
}
.Differencesplates .new-policy-modal {
  width: 1248px;
  max-width: 100%;
}
.adjust-buttons .button span,
.decIncPremium .button span {
  font-size: 14px;
}
.generalInsuranceSection .toggleIcon,
.lifeInsuranceSection .toggleIcon {
  display: none;
}
.changeMobileNumberModal .titleDescription {
  margin-top: 0;
}
.managemypolicycard .ftext {
  margin-top: 0;
}
.fund-redirect-tbl .startAdornment {
  left: 3px !important;
  top: 1px !important;
}
.bhdinputvox {
  width: 164px !important;
  height: 32px !important;
}
.redirectingfund .alertBox {
  display: flex;
  height: 40px;
  padding: 16px;
  align-items: center;
  gap: 16px;
}
.fund-redirect-tbl tbody > tr > td:nth-child(5) {
  min-width: 86px;
}
.fund-redirect-tbl tbody > tr > td:nth-child(6) {
  min-width: 67px !important;
}
[dir="rtl"] .expandbtn {
  direction: ltr;
}
.fundsSwitchingFlow table > tbody > tr:last-child,
.fundswtble tbody > tr:last-child,
table > tbody > tr:last-child td {
  border-bottom-width: 0px !important;
}
.fundsSwitchingFlow table > tbody > tr:last-child td,
.addfunds table > tbody > tr:last-child td,
.phtable > tbody > tr:last-child td {
  border-bottom: 0px !important;
}
.buyonlintable1 table > tbody > tr:last-child td {
  border-bottom-width: 0px;
}
.phtable > tbody > tr > td:last-child,
.phtable > thead > tr > th:last-child {
  border-right: 0;
}
.fund-redirect-tbl thead > tr > th:first-child {
  border-top-left-radius: 7px;
}
.fund-redirect-tbl thead > tr > th:last-child {
  border-top-right-radius: 7px;
}
.fund-redirect-tbl tfoot > tr > td:first-child {
  border-bottom-left-radius: 7px;
}
.fund-redirect-tbl tfoot > tr > td:last-child {
  border-bottom-right-radius: 7px;
}

.viewallbtn span {
  font-size: 14px;
  color: #00384d;
}
.enhancetabs button {
  justify-content: center;
}
.changepymentcylcmodal .new-policy-modal button span,
.Investmentstrategy button span,
.fundmanage button span,
.uploadDocsPage button span,
.plancard button span,
.fileupload button span,
.vheical-table table button span,
.addnewbenificiary .addbbottombutton button span {
  font-size: 14px;
  font-weight: 600;
}
.fileupload button span {
  font-weight: 400;
}
.Intimation {
  background-color: #d29404;
}
.Settlement {
  background-color: #00719a;
}
.date_of_birth .formField {
  min-height: auto !important;
  margin-bottom: 18px;
}
.claim-item {
  display: flex;
  height: 20px;
  padding: 0px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: max-content;
}
.addnewbenificiary .formField .endAdornment {
  right: 11px !important;
  top: 15px !important;
}
.addnewbenificiary .formField .endAdornment span {
  height: 48px;
}
.selectinput select {
  background-position: 98% 43% !important;
}
.Howinput .startAdornmentInput {
  height: 50px;
}
.EssentialCare button span, .toggleAddNewBeneficiary button span{font-size: 14px;}
.removeBeneficiary span{font-size: 14px;}
.ViewEssentialCareBenefitsDetailsPopup .new-policy-modal {
  width: 1248px;
  max-width: 100%;
  height: 90vh;
  overflow-y: hidden;
}.EssentialReviewYourApplication .new-policy-modal .close-modal-icon{display: none;}
.beneficiaryDetails .buyonlintable1 .mb-4:last-child{margin-bottom: 0;}
.cprNumPhotosfilupload .fileupload {justify-content: space-between;}
.cprNumPhotosfilupload .fileupload label{width: 25%;}
.BeneficiaryDetailsbox:first-child{margin-top: 0px;}
.EssentialCaremain .beneficiaryDetails table > tbody > tr > td:first-child{width: 40%;}
.EssentialCaremain .beneficiaryDetails table > tbody > tr > td{padding: 10px 24px;}
.EssentialReviewYourApplication .new-policy-modal{width: 548px; max-width: 100%;}
/*********** track a aim ***********/
@media screen and (max-width: 767px) {
  .beneficiaryDetails table{border: 1px solid #DDE2E4;}
  [dir="rtl"] .beneficiaryDetails table > tbody > tr > td{border-left-width: 0px;    border-left-width: 0px; padding-right: 16px;}
  [dir="rtl"] .beneficiaryDetails table > tbody > tr > td:first-child{border-bottom-width: 1px; }
  .onboardingslider .slick-dots li.slick-active button:before {
    opacity: .95 !important;
    background-color: #005574 !important;
    border-radius: 10px;
    color: transparent !important;
}
  .Howinput .startAdornment {
    left: 15px !important;
    top: 13px !important;
  }
  .enhancetabs button{height: 32px;}
  .apply-input button{height:50px;}
.stickybutton .content-start{
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
  .onboardingslider .slick-dots li button:before {
    background-color: #7db9cf !important;
    line-height: 25px;
    border-radius: 10px !important;
    width: 8px !important;
    height: 8px !important;
  }

  .onboardingslider .slick-dots li {
    width: 8px !important;
    height: 8px !important;
  }

  .RemoveFundAllocation span {
    font-size: 14px;
  }

  .policycard-slider .policy_card_root {
    min-width: 290px;
  }

  .startAdornment {
    position: absolute;
    left: 3px !important;
    top: 1px !important;
  }

  .changeyourplan .titleDescription,
  .fundsSwitchingFlow .titleDescription,
  .fundmanage .titleDescription {
    margin-top: 0;
  }

  .policycard-slider .policy_card_root .policy_card_content {
    justify-content: stretch;
  }

  .policycard-slider .policy_card_root .policy_card_content .policy_number {
    margin-top: 25px;
  }

  .policy-over .policyoverview .overviewcard:first-child .ftext {
    margin-top: 20px;
  }

  .banner-carousel-section .slick-slide,
  .banner-carousel-section .slick-track {
    /* width: 100% !important; */
  }

  /* .slick-list {
    padding: 0 !important;
  } */

  .policyoverview .overviewcard .ftext {
    font-weight: 400;
  }

  .managemypolicycard .bhdvalue {
    margin-top: 0px;
  }

  .AppleWallet .content-start {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
  }

  .filterApplied .activedot {
    position: absolute;
    top: 11px;
    right: 8px;
    width: 6px;
    height: 6px;
    background-color: #55C6EF;
    border-radius: 20px;
  }

  .edit-share .form-input input {
    height: 40px;
    width: 100px;
    font-size: 14px;
    color: #252C32;
    border: 1px solid #DDE2E4;
    border-radius: 6px;
  }

  .edit-share .form-input .relative .endAdornment .bg-gray-100 {
    width: 45px;
    text-align: center;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 38px;
    padding-top: 8px;
  }

  .bottom_bar .active a .truncate {
    color: #005574;
  }

  .bottom_bar .active a span img {
    filter: invert(21%) sepia(49%) saturate(2354%) hue-rotate(169deg) brightness(54%) contrast(91%);
  }

  .changeplatepopup .new-policy-modal {
    height: 359px;
    top: 25%;
  }



  .Toastify__toast {
    right: -37px;
  }

  .fundnamemodal .new-policy-modal,
  .TrusteesDetails .new-policy-modal {
    margin-top: 0;
    top: 0;
    height: 100vh;
    border-radius: 0 !important;
  }

  .fundnamemodal .new-policy-modal .close-modal-icon {
    justify-content: start;
    width: 100%;
  }

  .filterfund .new-policy-modal .close-modal-icon {
    justify-content: space-between;
    width: 100%;
  }

  .filterfund .new-policy-modal {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .allocationbox .formField {
    min-height: auto;
  }

  /* .allocationbox .formField input {
    width: 80px;
    height: 32px;
  } */

  .allocationbox .formField .endAdornment {
    right: 3px;
    top: 2px;
  }

  .allocationbox .formField .endAdornment .bg-gray-100 {
    width: 20px;
    height: 28px;
    text-align: center;
    padding-top: 3px;
  }

  .beneficiaryDetails .buyonlintable {
    margin: 0;
  }

  .buyonlintable table {
    border: 1px solid #DDE2E4
  }

  .buyonlintable table>tbody>tr>td {
    padding: 10px 20px;
  }

  .buyonlintable table>tbody>tr>td:first-child {
    border-bottom: 1px solid #DDE2E4;
    border-right: 1px solid #DDE2E4
  }

  .fund-managment .managemypolicycard .ftext .bhdvalue span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: #000;
  }

  .stickytitle .editmodal span {
    font-size: 16px;
  }

  .stickytitle .editmodal .items-center {
    text-align: center;
    justify-content: center;
  }

  .chngepassword button {
    position: fixed;
    bottom: 16px;
    width: 90%;
    margin: 0 auto;
  }

  .circlechart canvas {
    width: 90% !important;
    height: auto !important;
  }

  .policyoverviewtab .cardvalue {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }

  .policyoverviewtab .ftext {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #1A2024;
  }

  .policyoverviewtab .poverviewtab {
    padding: 16px;
    height: 125px;
  }

  .yourProfile .inputGrid .form-input {
    width: 100%;
  }

  .addnewbenificiary .new-policy-modal {
    padding-bottom: 20%;
    margin-top: 0;
    top: 0;
    height: 100vh;
    border-radius: 0 !important;
  }

  .EditSharePercentage .new-policy-modal {
    width: 500px;
    max-width: 100%;
    margin-top: 0;
    height: 100vh;
    top: 0;
    border-radius: 0 !important;
  }

  .paymenthistroybox .bhdvalue span:last-child {
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    color: #252C32;
  }

  .addnewbenificiary .new-policy-modal {
    padding-bottom: 20%;
  }

  .fundmanagementbox .fund-managment .managemypolicycard {
    flex-direction: column;
  }

  .fundmanagementbox .fund-managment {
    height: auto;
    padding: 14px;
  }

  .downloaddocumentsmodal .close-modal-icon {
    justify-content: space-between;
  }

  .editpolicymodal .new-policy-modal .editinput {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    height: 50px;
    font-weight: 400;
    font-size: 18px;
  }

  .editpolicymodal .new-policy-modal .close-modal-icon {
    justify-content: space-between;
  }

  .editpolicymodal .new-policy-modal .editbtnblue {
    padding: 16px;
    width: 100%;
    border-radius: 10px;
    background: var(--Primary-PR400, #55C6EF);
    margin-top: 24px;
  }

  .paymenthistory .cardvalue,
  .PerformanceCard .cardvalue {
    font-size: 12px;
  }

  .PerformanceCard .bhdvalue span:last-child {
    font-size: 15px;
  }

  .fundcard .cardvalue {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-top: 10px;
    line-height: 1.3;
  }

  .fundcard .bhdvalue span:first-child {
    font-size: 16px;
  }

  .fundcard .bhdvalue span:last-child {
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
  }

  .managemypolicy .managemypolicycard {
    flex-direction: row;
    display: flex;
  }

  .managepolicy .manage-policy-box div:first-child {
    flex-direction: row;
  }

  .managemypolicycard span:last-child {
    margin-top: 0 !important;
  }

  .manage-policy-box span:last-child {
    line-height: 120%;
    margin-top: 8px;
    display: flex;
    font-size: 14px;
    line-height: 1;
  }

  .newprimium input {
    height: 77px;
    align-items: center;
    padding-left: 16px;
    font-size: 24px;
  }

  .changepymentcylcmodal .close-modal-icon {
    justify-content: flex-start;
  }

  .dateselect input {
    color: #000;
    font-weight: normal;
    height: 45px;
    background-color: white;
    width: 400px;
    max-width: 100%;
    display: flow-root;
  }

  .cpr-exists-modal .new-policy-modal {
    height: auto;
    bottom: 0;
    position: fixed;
    display: inline;
    top: unset;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .bkimag {
    background-image: url('../src/assets/images/registerbkimg.jfif');
    background-position: 47% 73%;
  }
  .change_plan span{font-size: 14px;}
}

.PROGRESS,
.Repairing {
  display: flex;
  width: max-content;
  background-color: #d29404;
  padding: 0px 8px;
}
.REJECTED,
.Rejected {
  display: flex;
  background-color: #cc0905;
  width: max-content;
  padding: 0px 8px;
}
.Intimation {
  display: flex;
  background-color: #84919a;
  width: max-content;
  padding: 0px 8px;
}
.Settlement {
  display: flex;
  background-color: #00719a;
  width: max-content;
  padding: 0px 8px;
}
.Survey {
  display: flex;
  background-color: #88837b;
  width: max-content;
  padding: 0px 8px;
}
.Closed {
  display: flex;
  background-color: #119c2b;
  width: max-content;
  padding: 0px 8px;
}
.Registration {
  display: flex;
  background-color: #d29404;
  width: max-content;
  padding: 0px 8px;
}
.Paid {
  border-radius: 10px;
  background-color: #119c2b;
  display: flex;
  width: 50px;
  height: 20px;
  padding: 0px 8px;
  align-items: center;
}
.COMPLETED,
.Completed {
  display: flex;
  width: 90px;
  background-color: #00719a;
  text-align: center;
}
.track-claim-stepper {
  margin-left: 13px;
  margin-bottom: 36px;
  align-items: flex-end;
  justify-content: left;
}
.downloaddocumentsmodal .new-policy-modal {
  width: 560px;
  max-width: 100%;
}
.downloaddocumentsmodal .new-policy-modal .close-modal-icon {
  justify-content: space-between;
}
.downloaddocumentsmodal .new-policy-modal .downloadbtn .button {
  width: 100%;
}
.fontlg span {
  font-size: 18px;
}
.changeplatepopup .close-modal-icon {
  justify-content: space-between;
}
.profile-seting-multitel button .content-start {
  color: #00384d;
}
.downloadbtn button {
  width: 100%;
}
.loader {
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.filterfund .isActive {
  background-color: #e3f8ff;
  color: white;
  border: 2px solid #00719a;
}
.claimdetailsbox .button-blue .button span,
.AddFund span {
  font-size: 14px;
}
.managemypolicy .managemypolicycard .cardvalue {
  font-size: 18px;
  font-weight: 500;
}
.managemypolicy .managemypolicycard .ftext {
  margin-top: 10px;
}
.sidebar .li-box:first-child li:first-child {
  display: none;
}
.numberPlatesTypesPopup .grid .plate-box:nth-child(odd) {
  border-bottom: 1px solid #dde2e4;
}
[dir="rtl"] .numberPlatesTypesPopup .grid .plate-box:nth-child(even) {
  border-bottom: 1px solid #dde2e4;
  border-right: 1px solid #dde2e4;
  border-left: 0px;
}
[dir="rtl"] .numberPlatesTypesPopup .grid .plate-box:last-child {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 0px;

  border-bottom: 0;
}
[dir="rtl"] .numberPlatesTypesPopup .grid .plate-box:nth-child(2) {
  border-top-right-radius: 0;
  border-top-left-radius: 16px;
}
[dir="rtl"] .numberPlatesTypesPopup .grid .plate-box:first-child {
  border-top-right-radius: 16px;
}
[dir="rtl"] .numberPlatesTypesPopup .grid .plate-box:nth-last-child(2) {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 16px;

  border-bottom: 0;
}
.numberPlatesTypesPopup .grid .plate-box:nth-child(even) {
  border-bottom: 1px solid #dde2e4;
  border-left: 1px solid #dde2e4;
}
.numberPlatesTypesPopup .grid .plate-box:first-child {
  border-top-left-radius: 16px;
}
.numberPlatesTypesPopup .grid .plate-box:nth-child(2) {
  border-top-right-radius: 16px;
}
.numberPlatesTypesPopup .grid .plate-box:last-child {
  border-bottom-right-radius: 16px;
  border-bottom: 0;
}
.numberPlatesTypesPopup .grid .plate-box:nth-last-child(2) {
  border-bottom-left-radius: 16px;
  border-bottom: 0;
}
.Differencesplates .new-policy-modal {
  width: 1248px;
  max-width: 100%;
}
.adjust-buttons .button span,
.decIncPremium .button span {
  font-size: 14px;
}
.generalInsuranceSection .toggleIcon,
.lifeInsuranceSection .toggleIcon {
  display: none;
}
.changeMobileNumberModal .titleDescription {
  margin-top: 0;
}
.managemypolicycard .ftext {
  margin-top: 0;
}
.fund-redirect-tbl .startAdornment {
  left: 3px !important;
  top: 1px !important;
}
.bhdinputvox {
  width: 164px !important;
  height: 32px !important;
}
.redirectingfund .alertBox {
  display: flex;
  height: 40px;
  padding: 16px;
  align-items: center;
  gap: 16px;
}
.fund-redirect-tbl tbody > tr > td:nth-child(5) {
  min-width: 86px;
}
.fund-redirect-tbl tbody > tr > td:nth-child(6) {
  min-width: 67px !important;
}
[dir="rtl"] .expandbtn {
  direction: ltr;
}
.fundsSwitchingFlow table > tbody > tr:last-child,
.fundswtble tbody > tr:last-child {
  border-bottom-width: 0px !important;
  border-bottom-right-radius: 23px;
}
.fundsSwitchingFlow table > tbody > tr:last-child td,
.addfunds table > tbody > tr:last-child td,
.phtable > tbody > tr:last-child td {
  border-bottom: 0px !important;
  border-bottom-left-radius: 10px;
}
.buyonlintable1 table > tbody > tr:last-child td {
  border-bottom-width: 0px;
}
.phtable > tbody > tr > td:last-child,
.phtable > thead > tr > th:last-child {
  border-right: 0;
}
.fund-redirect-tbl thead > tr > th:first-child {
  border-top-left-radius: 7px;
}
.fund-redirect-tbl thead > tr > th:last-child {
  border-top-right-radius: 7px;
}
.fund-redirect-tbl tfoot > tr > td:first-child {
  border-bottom-left-radius: 7px;
}
.fund-redirect-tbl tfoot > tr > td:last-child {
  border-bottom-right-radius: 7px;
}
.paymenthistory .managemypolicycard .ftext,
.policyoverviewtab .managemypolicycard .ftext {
  margin-top: 16px;
}
.viewallbtn span {
  font-size: 14px;
  color: #00384d;
}
.enhancetabs button {
  justify-content: center;
}
.changepymentcylcmodal .new-policy-modal button span,
.Investmentstrategy button span,
.fundmanage button span,
.uploadDocsPage button span,
.plancard button span,
.fileupload button span,
.vheical-table table button span,
.addnewbenificiary .addbbottombutton button span {
  font-size: 14px;
  font-weight: 600;
}
.fileupload button span {
  font-weight: 400;
}
.Intimation {
  background-color: #d29404;
}
.Settlement {
  background-color: #00719a;
}
.date_of_birth .formField {
  min-height: auto !important;
  margin-bottom: 18px;
}
.claim-item {
  display: flex;
  height: 20px;
  padding: 0px 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  width: max-content;
}
.addnewbenificiary .formField .endAdornment {
  right: 11px !important;
  top: 15px !important;
}
.addnewbenificiary .formField .endAdornment span {
  height: 48px;
}
.selectinput select {
  background-position: 98% 43% !important;
}
/*********** track a claim ***********/
@media screen and (max-width: 767px) {
  .RemoveFundAllocation span {
    font-size: 14px;
  }
  .policycard-slider .policy_card_root {
    min-width: 300px;
  }
  .startAdornment {
    position: absolute;
    left: 3px !important;
    top: 1px !important;
  }
  .changeyourplan .titleDescription,
  .fundsSwitchingFlow .titleDescription,
  .fundmanage .titleDescription {
    margin-top: 0;
  }
  .policycard-slider .policy_card_root .policy_card_content {
    justify-content: stretch;
  }
  .policycard-slider .policy_card_root .policy_card_content .policy_number {
    margin-top: 5px;
  }
  .policy-over .policyoverview .overviewcard:first-child .ftext {
    margin-top: 0px;
  }
  .banner-carousel-section .slick-slide,
  .banner-carousel-section .slick-track {
    /* width: 100% !important; */
  }
  /* .slick-list {
    padding: 0 !important;
  } */
  .policyoverview .overviewcard .ftext {
    font-weight: 400;
    font-size: 18px;
  }
  .managemypolicycard .bhdvalue {
    margin-top: 0px;
  }
  .AppleWallet .content-start {
    color: #fff;
    font-size: 16px;
    font-style: normal;
  }
  .filterApplied .activedot {
    position: absolute;
    top: 11px;
    right: 8px;
    width: 6px;
    height: 6px;
    background-color: #55c6ef;
    border-radius: 20px;
  }
  .edit-share .form-input input {
    height: 40px;
    width: 100px;
    font-size: 14px;
    color: #252c32;
    border: 1px solid #dde2e4;
    border-radius: 6px;
  }
  .edit-share .form-input .relative .endAdornment .bg-gray-100 {
    width: 45px;
    text-align: center;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    height: 38px;
    padding-top: 8px;
  }
  .bottom_bar .active a .truncate {
    color: #005574;
  }
  .bottom_bar .active a span img {
    filter: invert(21%) sepia(49%) saturate(2354%) hue-rotate(169deg)
      brightness(54%) contrast(91%);
  }
  .changeplatepopup .new-policy-modal {
    height: 359px;
    top: 25%;
  }

  .Toastify__toast {
    right: -37px;
  }
  [dir="rtl"] .Toastify__toast {
    right: 120px;
}
  .fundnamemodal .new-policy-modal,
  .TrusteesDetails .new-policy-modal {
    margin-top: 0;
    top: 0;
    height: 100vh;
    border-radius: 0 !important;
  }
  /* .sliderbkimg{
    background-image: url(../src/assets/images/onboarding_one_bg.svg);
    background-size: cover;
  } */
  .cardbkimg{

  }
  
  .blurbkimg{
    background-image: url(../src/assets/images/Rectangleblurbk.svg);
    background-size: cover;
  }
  .fundnamemodal .new-policy-modal .close-modal-icon {
    justify-content: start;
    width: 100%;
  }
  .filterfund .new-policy-modal .close-modal-icon {
    justify-content: space-between;
    width: 100%;
  }
  .filterfund .new-policy-modal {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .allocationbox .formField {
    min-height: auto;
  }
  .allocationbox .formField input {
    width: 80px;
    height: 32px;
    border-radius: 6px;
    max-height: 32px;
    min-height: 32px;
  }
  .allocationbox .formField .endAdornment {
    right: 3px;
    top: 2px;
  }
  .allocationbox .formField .endAdornment .bg-gray-100 {
    width: 20px;
    height: 28px;
    text-align: center;
    padding-top: 3px;
  }
  .beneficiaryDetails .buyonlintable {
    margin: 0;
  }
  .buyonlintable table {
    border: 1px solid #dde2e4;
  }
  .buyonlintable table > tbody > tr > td {
    padding: 10px 20px;
  }
  .buyonlintable table > tbody > tr > td:first-child {
    border-bottom: 1px solid #dde2e4;
    border-right: 1px solid #dde2e4;
  }
  .fund-managment .managemypolicycard .ftext .bhdvalue span {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: #000;
  }
  .stickytitle .editmodal span {
    font-size: 16px;
  }
  .stickytitle .editmodal .items-center {
    text-align: center;
    justify-content: center;
  }
  .chngepassword button {
    position: fixed;
    bottom: 16px;
    width: 90%;
    margin: 0 auto;
  }
  .circlechart canvas {
    width: 90% !important;
    height: auto !important;
  }
  .numberPlatesTypesPopup .grid .plate-box:nth-child(even) {
    border-bottom: 0px solid #dde2e4;
    border-left: 0px solid #dde2e4;
}
.numberPlatesTypesPopup .grid .plate-box:first-child  {
  border-top-left-radius: 0px;
}
.numberPlatesTypesPopup .grid .plate-box:nth-child(2) {
  border-top-right-radius: 0px;
}
.numberPlatesTypesPopup .grid .plate-box:nth-child(odd) {
  border-bottom: 0px solid #dde2e4;
}
  .policyoverviewtab .cardvalue {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
  .policyoverviewtab .ftext {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #1a2024;
    width: max-content;
  }
  .managemypolicy .managemypolicycard .ftext {
    margin-top: 0px;
}
  .policyoverviewtab .poverviewtab {
    padding: 16px;
    height: max-content;
  }
  .yourProfile .inputGrid .form-input {
    width: 100%;
  }
  .addnewbenificiary .new-policy-modal {
    padding-bottom: 20%;
    margin-top: 0;
    top: 0;
    height: 100vh;
    border-radius: 0 !important;
  }
  .EditSharePercentage .new-policy-modal {
    width: 500px;
    max-width: 100%;
    margin-top: 0;
    height: 100vh;
    top: 0;
    border-radius: 0 !important;
  }
  .paymenthistroybox .bhdvalue span:last-child {
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    color: #252c32;
  }
  .addnewbenificiary .new-policy-modal {
    padding-bottom: 30%;
  }
  .fundmanagementbox .fund-managment .managemypolicycard {
    flex-direction: column;
  }
  .fundmanagementbox .fund-managment {
    height: 190px;
    padding: 14px;
  }
  .downloaddocumentsmodal .close-modal-icon {
    justify-content: space-between;
  }
  .editpolicymodal .new-policy-modal .editinput {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    height: 50px;
    font-weight: 400;
    font-size: 18px;
  }
  .editpolicymodal .new-policy-modal .close-modal-icon {
    justify-content: space-between;
  }
  .editpolicymodal .new-policy-modal .editbtnblue {
    padding: 16px;
    width: 100%;
    border-radius: 10px;
    background: var(--Primary-PR400, #55c6ef);
    margin-top: 24px;
  }
  .paymenthistory .cardvalue,
  .PerformanceCard .cardvalue {
    font-size: 12px;
  }
  .PerformanceCard .bhdvalue span:last-child {
    font-size: 15px;
  }
  .fundcard .cardvalue {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-top: 0px;
    line-height: 1.3;
  }
  .fundcard .bhdvalue span:first-child {
    font-size: 16px;
  }
  .fundcard .bhdvalue span:last-child {
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
  }
  .managemypolicy .managemypolicycard {
    flex-direction: row;
    display: flex;
  }
  .managepolicy .manage-policy-box div:first-child {
    flex-direction: row;
  }
  .managemypolicycard span:last-child {
    margin-top: 0 !important;
  }
  .manage-policy-box span:last-child {
    line-height: 120%;
    margin-top: 8px;
    display: flex;
    font-size: 14px;
    line-height: 1;
  }
  .newprimium input {
    height: 77px;
    align-items: center;
    padding-left: 16px;
    font-size: 24px;
  }
  .changepymentcylcmodal .close-modal-icon {
    justify-content: flex-start;
  }
  .dateselect input {
    color: #000;
    font-weight: normal;
    height: 45px;
    background-color: white;
    width: 400px;
    max-width: 100%;
    display: flow-root;
  }
  .cpr-exists-modal .new-policy-modal {
    height: auto;
    bottom: 0;
    position: fixed;
    display: inline;
    top: unset;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .bkimag {
    background-image: url("../src/assets/images/registerbkimg.jfif");
    background-position: 47% 73%;
  }

  /******** life insurence tab *******************/
  .lifeinsurenrce-cards .manage-policy-box {
    background: var(--Life-Colors-Skin-200, #fbf7f0);
    border: 1px solid var(--Light-Gray-Light-Gray-2, #dde2e4);
    outline: 2px solid transparent;
  }
  .lifeinsurenrce-cards .manage-policy-box:hover {
    background: var(--Life-Colors-Skin-200, #fbf7f0);
  }
  .lifeinsurencepolicycard .policy-cards {
        display: grid;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        gap: 16px;
  }
  .lifeinsurencepolicycard .policy-cards .service-cards {
    gap: 0px;
    text-align: center;
    width: 100%;
    justify-content: flex-start;
    max-width: 100%;
}

  .Assistance .policy-cards .service-cards {
    height: auto;
  }
  /******** life insurence tab *******************/
  .policyOverviewDiv .fileclaim-box .policy-cards .service-cards {
    min-width: unset;
    display: flex;
    width: 170px;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    height: 93px;
  }
  .policyOverviewDiv
    .fileclaim-box
    .policy-cards
    .service-cards
    .service-title {
    color: #1a2024;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 16.8px */
  }
  /* .fileclaim-box .policy-cards{display: flow;} */
  .mainCarDetailsModal {
    position: absolute;
  }
  .renvewaltitile .titleDescription p {
    display: none;
  }
  .renvewaltitile .titleDescription {
    margin-bottom: 0px;
  }
  .otp_input {
    height: 65px;
    width: 23% !important;
  }
  .numberPlatesTypesPopup .grid .item:first-child {
    border: 0px;
  }
  .increse-box-tab .yesno-tab-box {
    gap: 10px;
    display: inline-block;
    width: 100%;
  }
  .increse-box-tab .yesno-tab-box button {
    width: 100%;
  }
  .increse-box-tab .yesno-tab-box button {
    display: inline-block;
  }
  .increse-box-tab .yesno-tab-box button span:first-child {
    width: 50%;
    display: inline-block;
    text-align: left;
  }
  .increse-box-tab .yesno-tab-box button span:last-child {
    width: 50%;
    display: inline-block;
    text-align: right;
  }
  .carfinancepage.yesno-tab-box button span:first-child {
    width: 50%;
  }
  .noClaimCertuficate .fileupload .input {
    width: 70%;
  }
  .mobile_only button {
    margin-bottom: 0px !important;
  }
  .verify-content .otp_input {
    width: 80px;
    height: 80px;
  }
  .bpy-2 {
    margin-left: 16px;
    border-radius: 24px;
    background: var(--Light-Gray-Light-Gray-5, #f6f8f9);
    padding: 8px;
    position: relative;
    margin-bottom: 30px;
  }
  .AdjustCarValuePopup .new-policy-modal {
    width: 100%;
  }
  .AdjustCarValuePopup .formField .w-full input {
    background-color: #e5e9eb;
  }
  .adjustmodaltabs .adjusttabbutton {
    padding-top: 30px;
  }
  .documentsReadyInfo .content {
    margin-top: 35px;
  }
  .img-td img {
    margin: 0 auto;
  }
  .planselectormodalmain .new-policy-modal {
    padding: 16px 0 26px 0;
  }
  .planselectormodalmain .new-policy-modal h2 {
    padding-left: 16px;
  }
  .planselectormodalmain .close-modal-icon {
    padding-right: 20px;
  }
  .backdropModal .new-policy-modal {
    border-radius: 26px;
  }
  .summarypopup .summarymodal .close-modal-icon {
    justify-content: flex-end;
  }
  .summarypopup .summarymodal h1 {
    margin-top: -38px;
  }
  .uplodad-car-div {
    margin-top: 3rem !important;
  }
  .otpinput div {
    gap: 7px;
  }
  /* .policycardbox .policy_card_root img {
    width: 100%;
  } */
  .policycardbox .policy_card_root {
    margin-bottom: 16px;
  }
  .policy_card_root button span{font-size: 14px;}
  /* .policy-over .overviewcard:last-child {
    width: 170px;
    max-width: 170px;
  } */
  .managepolicy .managemypolicycard {
    display: flex;
  }
  .PerformanceCardfund .fundcard {
    padding: 16px;
  }
  .paymenthistroybox .bhdvalue .ftext {
    font-size: 16px !important;
  }
  .new-policy-modal .emegencymodal{padding: 0;}
  .emregencymodal .new-policy-modal, .summarypopup .summarymodal .new-policy-modal, .editpolicymodal .new-policy-modal, .downloaddocumentsmodal .new-policy-modal, .expressShieldPlan .new-policy-modal, .EssentialReviewYourApplication .new-policy-modal{
    position: absolute;
    bottom: 0;
    height: max-content;
    top: unset;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow-y: auto;
    max-height: 95vh;
    /* padding-top: 0; */
}
.summarypopup .summarymodal .new-policy-modal{padding-top: 11px;}
.summarypopup .summarymodal .new-policy-modal .close-modal-icon button{margin-bottom: -13px;}
.emregencymodal .close-modal-icon{    position: sticky;
  top: 0;
  background-color: white;
  padding-top: 16px}
  .paymenthistory .managemypolicycard .ftext, .policyoverviewtab .managemypolicycard .ftext {
    margin-top: 12px;
}
.workshop {min-height: auto;}
.editsharebuttonbox button span, .confirmCancellation .button span{font-size: 14px;}
.seleectfund button span{font-size: 14px; font-weight: 400;}
.SharePercentageTable .formField input{min-height: 40px;}
.seleectfund .yesno-tab-box{margin-left: 0 !important;}
.filterfund .new-policy-modal{padding: 20px;}
.confirmCancellation .policy_card_root{min-width: 297px;}
.confirmCancellation .button{min-height: 40px;
  max-height: 40px;}
  .uploadbuttons button div{justify-content: center;}
  .uploadbuttons button span{font-size: 14px;}
  .EssentialCaremain .addnewbenificiary .new-policy-modal .content {
    margin-top: 35px!important;
}
.EssentialCaremain .addnewbenificiary .new-policy-modal{
  margin-top: 0%!important;
  height: 100vh!important;
}
 .incresedecrese .new-policy-modal {
  border-radius: 0;
  margin-top: 0% !important;
  height: 100vh !important;
  top: 0;
  padding-bottom: 60px;
}
.incresedecrese .bpy-2 {
  margin-left: 0;
  margin-top: -11px;
  margin-bottom: 30px;
}
.FundsPremiumTable .button .content-start{font-size: 14px;}
}@media screen and (max-width: 500px){
  .lifeinsurencepolicycard .policy-cards .service-cards{height: 110px;}
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .changeplatepopup .new-policy-modal {
    width: 500px;
    max-width: 100%;
  }

  .fund-redirect-tbl tr > td:nth-child(2) {
    width: 30%;
  }
  .fundnamemodal .new-policy-modal {
    margin-top: 241%;
  }
  .selectfundtble th:nth-child(2) {
    min-width: 300px;
    left: 0 !important;
  }
  .managemypolicy .managemypolicycard,
  .managepolicy .managemypolicycard {
    flex-direction: row;
    display: flex;
    align-items: center;
  }
  .paymenthistory .paymenthistroybox:first-child,
  .paymenthistory .paymenthistroybox:nth-child(2) {
    min-width: 100px !important;
    width: 100px;
    max-width: 150px;
  }
  .paymenthistroybox .managemypolicycard {
    flex-direction: column;
  }
  .paymenthistroybox .bhdvalue span:last-child {
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    color: #252c32;
  }
  .paymenthistroybox .cardvalue {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
  .policyoverview .overviewcard .ftext {
    font-size: 14px;
  }
  .AdjustCarValuePopup .new-policy-modal {
    width: 85%;
  }
  .download-wrapper > .downloadbtn:nth-child(4),
  .download-wrapper > .downloadbtn:nth-child(5) {
    max-width: 50%;
  }
  .paymenthistroybox .bhdvalue span:first-child {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: #252c32;
  }
  .managepolicy .managemypolicycard {
    align-items: center;
  }
  .fundperformencecard .fundperformencechart {
    flex-direction: column;
  }
  .PerformanceCardfund .managemypolicycard,
  .policyoverviewtab .managemypolicycard {
    flex-direction: column;
  }
  .PerformanceCardfund .fundcard {
    height: 135px;
  }
  .vehical-download .downloadbtn {
    width: 55%;
  }
  .PerformanceCardfund .fundcard {
    padding: 10px;
  }
  .PerformanceCardfund .fundcard .managemypolicycard {
    align-items: flex-start;
  }
  .circlechart canvas {
    height: 450px !important;
    width: auto !important;
  }

}
/* Portrait */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .addnewbenificiary .new-policy-modal {
      margin-top: 0%;
  }
}
[dir="rtl"] .addnewbenificiary .formField .endAdornment {
  width: 50px;
  left: 3px !important;
  right: unset !important;
}
[dir="rtl"] .addnewbenificiary .formField .endAdornment span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
input:focus{outline: 2px solid black;}
input:focus,textarea:focus,select:focus{outline: 2px solid black; border: 1px solid white;}
.optioncard, .manage-policy-box, .plancard {outline: 1px solid #DDE2E4;}
[dir="rtl"] .carfinancepage .Howinput .formField .startAdornmentInput { padding-right: 103px; padding-left: 0px!important;}
iframe {
  /* display: none; */
}
.buyonlineinputs .formField{width: 100%;}
input::-ms-reveal,
      input::-ms-clear {        display: none;
      }
      .TrusteesDetails{}

input:focus[type="checkbox"] { outline: none; }
.fund-redirect-tbl .form-input input{    min-height: 32px;}
.EditSharePercentagebtn button span{font-size: 14px;}
.EditSharePercentagetbl table > tbody > tr:last-child td:first-child{ border-bottom-left-radius: 10px;}
.canclebutton span{color: white;}
 .choose-your-plan .new-policy-modal .sign_inForm .formField .endAdornment{top: 13px;}
 .EssentialCaremain .addnewbenificiary .new-policy-modal{    width: 560px;
  max-width: 100%;
  margin-top: 10%;
  height: 95vh;
  overflow-y: scroll;}
 .EssentialCaremain .addnewbenificiary .new-policy-modal .content {margin-top: -40px;}

.fundsSwitchingFlow table > thead > tr > th .checkboximg, .fundsSwitchingFlow table > tbody > tr > td .checkboximg{width: 16px;}
.essentialmobile .country-selector {border-radius: 10px;}
.selectfundtble .sticky-column:first-child{box-shadow: unset; border-top-left-radius: 10px;}
.selectfundtble th:last-child{box-shadow: unset; border-top-right-radius: 10px;}

.selectfundtble{box-shadow: unset; border-top-right-radius: 10px;}

#storybook-root .endAdornment { top:10px !important }
.storybookTelInput  input {
  border-radius: 10px;
}

.storybookToggleBtn .toggleIcon {
padding-top: 5px;
padding-left: 10px;
}
