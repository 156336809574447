.main_layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.no_header {
    padding-top: 0;
}

.content_wrapper {
    display: flex;
    flex: 1;
}

.body {
    flex: 1;
    overflow-y: auto;

}
