


.policy_card_content.dark_text h3{
    color: #1A2024;
}

.policy_card_content.dark_text .card-title{
    color: #1A2024;
  
}
.policy_card_content.dark_text .date-info{
    
    color: var(--Dark-Gray-Dark-Gray-1, #1A2024);
}
.policy_card_content.dark_text .policy_number{
    
    color: var(--Dark-Gray-Dark-Gray-1, #1A2024);
}

