.sticky-column {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 1;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sticky-column:nth-child(2) {
    left: 15px;
}
tr {
    position: relative;
}
